import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'


export const UserInfo = () => {
  const { user } = useAuth0()

  if (!user) {
    return null
  }

  return (
    <div>
      <h5 className='text-user-info'>
        Logged in as user: <span style={{ color: 'red' }}>{user.name}</span>
      </h5>
    </div>
  )
}
