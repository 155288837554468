import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

export const LogoutButton = () => {
  const { logout } = useAuth0()

  return (
    <button
      className='btn btn-danger btn-sm' size='sm' 
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin + '/bat' } })}
    >
      Log Out
    </button>
  )
}

