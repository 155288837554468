import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <button className='btn btn-info btn-sm' style={{ paddingLeft: '12px', paddingRight: '12px' }} onClick={() => loginWithRedirect()}>
      Log In
    </button>
  )
}


