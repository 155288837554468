import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ContactInfo from '../components/ContactInfo'
import { Container, Row, Col, Card } from 'react-bootstrap'
import lottie from 'lottie-web'
import networkLottie from '../lottie/lf30_editor_mhdbnbjm.json'
import StartImage from '../components/Startimage'

// USer Info:
// import { UserInfo } from '../components/UserInfo';

// Nav bar for auth0:
import { NavBarButtons } from '../components/nav-buttons/nav-bar-buttons'

const BatSystems = () => {
  // load lottie file onLoad:
  useEffect(() => {
    // Changed to be destroyed after one run:
    const instanceOfLottie = lottie.loadAnimation({
      container: document.querySelector('#net-lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: networkLottie,
    })
    return () => instanceOfLottie.destroy()
  }, [])

  return (
    <Container>
      <Row>
        <Col>
          <ContactInfo />
        </Col>
      </Row>
      <Row style={{ marginBottom: '2rem', marginTop: '0.3rem' }}>
        {/* <div id='net-lottie' style={{ height: 100 }}></div> */}
        <Col>
          <NavBarButtons />
          <h3 className='text-white'>Customer: BAT</h3>
          {/* <h6 className='text-white'>Select to view:</h6> */}
        </Col>
      </Row>
      <Row>
        {/* <Col>
          <Card border='secondary' style={{ width: '20rem' }}>
            <Card.Body style={{ backgroundColor: 'black' }}>
              <Card.Title>
                {' '}
                <p>
                  <Link to='/bat/store' className='text-link'>
                    STORES
                  </Link>
                </p>
              </Card.Title>
              <Card.Text style={{ color: 'grey' }}>In store screens.</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
        {/* <Col>
          <Card border='secondary' style={{ width: '20rem' }}>
            <Card.Body style={{ backgroundColor: 'black' }}>
              <Card.Title>
                {' '}
                <p>
                  <Link to='/bat/furniture' className='text-link'>
                    FURNITURES
                  </Link>
                </p>
              </Card.Title>
              <Card.Text style={{ color: 'grey' }}>Mobile furnitures with touch-screens.</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
        <Col>
          <Card border='secondary' style={{ width: '20rem' }}>
            <Card.Body style={{ backgroundColor: 'black' }}>
              <Card.Title>
                {' '}
                <p>
                  <Link to='/bat/routers' className='text-link'>
                    NETWORK
                  </Link>
                </p>
              </Card.Title>
              <Card.Text style={{ color: 'grey' }}>Network Routers status board</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* IMAGE ON START PAGE: */}
      <Row style={{ marginTop: 50 }}>
        {/* <div id='net-lottie' style={{ height: 100 }}></div> */}

        <Col>
          <StartImage />
        </Col>
      </Row>
    </Container>
  )
}

export default BatSystems
