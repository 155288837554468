import React, { useState } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap'

const baseId = process.env.REACT_APP_BASE_ID_TABLE
// const key = process.env.REACT_APP_API_KEY_TABLE
const table = process.env.REACT_APP_TABLE_NAME
// new PAT
const pat = process.env.REACT_APP_AT_API_PAT
const apiBaseUrl = process.env.REACT_APP_AT_API_BASE_URL     

// const apiUrl = `https://api.airtable.com/v0/${baseId}/${table}`
const apiUrl = `${apiBaseUrl}/${baseId}/${table}`

const ContactForm = () => {
  const [status, setStatus] = useState('Send message')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [variant, setVariant] = useState('warning')

  // send form
  const handleSubmit = async (e) => {
    setVariant('info') // color
    setStatus('Sending')
    e.preventDefault()
    // console.log('form submitted')
    const { name, email, message } = e.target.elements

    const data = {
      fields: {
        name: name.value,
        email: email.value,
        message: message.value,
      },
    }

    // Contact server:
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        Authorization: `${pat}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    await response.json()

    if (!response.ok) {
      setVariant('danger') // color
      setStatus('Error')
      // console.log(response.statusText)
      throw new Error(response.statusText)
    } else {
      console.log('network response was ok')
      setSubmitSuccess(true)
      setVariant('success') // color
      setStatus('Message sent ok!')
      console.log('submitSuccess is now:', submitSuccess)
      setTimeout(() => {
        window.location.reload(true)
      }, 2000)
      return
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit} className='text-muted'>
        <Row className='mb-3'>
          <Form.Group as={Col}>
            <Form.Label className='text-white'>Name</Form.Label>
            <Form.Control type='text' id='name' required />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className='text-white'>Email</Form.Label>
            <Form.Control type='email' id='email' required placeholder='your@email.com' />
          </Form.Group>
        </Row>

        <Form.Group className='mb-3'>
          <Form.Label className='text-white'>Message</Form.Label>
          <Form.Control as='textarea' id='message' required rows={3} />
        </Form.Group>

        <div className='d-grid gap-2'>
          <Button variant={variant} size='sm' active type='submit'>
            {status}
          </Button>
        </div>
      </Form>
      <section style={{ height: 40 }}></section>
    </>
  )
}

export default ContactForm
