import React from 'react'
// import Logo from '../images/Auth0_white.png'

export const AuthLogo = () => {
  return (
    <div style={{ marginTop: '2rem' }}>
      {/* <img style={{ width: '5rem', opacity: 0.2, marginTop: '2rem' }} src={Logo} alt='auth logo' /> */}
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 841.9 595.3'>
        <g width='81' height='24' opacity='0.3' transform='scale(0.25) translate(0 -5)'>
          <path
            d='M46,0H26.7l6,18.6H52L36.4,29.7l6,18.7C52.4,41.1,55.7,30,52,18.6L46,0z M1.5,18.6h19.3l6-18.6H7.4L1.5,18.6 C-2.2,30,1,41.1,11.1,48.4l6-18.7L1.5,18.6z M11.1,48.4l15.6,11l15.6-11L26.7,37.1L11.1,48.4z M156.8,18.2c-4.6,0-7.5,2.1-8.9,5.4 h-0.3V8.3h-6.8v40h7V30.9c0-4.3,2.7-6.8,6.4-6.8s5.8,2.4,5.8,6.4v17.8h7v-19C167,22.2,163,18.2,156.8,18.2z M186,8.2 c-9.5,0-15.2,7.4-15.2,20.3S176.4,49,186,49c9.5,0,15.2-7.5,15.2-20.5S195.5,8.2,186,8.2z M178,28.5c0-9.4,3.1-14.3,8-14.3 c3.4,0,5.8,2.3,7.1,6.7l-14.9,10.8C178,30.8,178,29.7,178,28.5z M186,42.9c-3.4,0-6-2.4-7.2-7.2l15-10.9c0.1,1.2,0.2,2.5,0.2,3.7 C194,38,190.9,42.9,186,42.9z M109.2,35.8c0,4.5-3.2,6.7-6.3,6.7c-3.1,0-5.6-2.4-5.6-6.1V18.5h-7v18.9c0,7.2,4.1,11.2,9.9,11.2 c4.4,0,7.6-2.4,8.9-5.7h0.3v5.3h6.8V18.5h-7L109.2,35.8L109.2,35.8z M74.4,18.1c-6.3,0-11,2.8-12.6,8.2l6.5,0.9 c0.7-2.1,2.7-3.8,6.1-3.8c3.4,0,5,1.6,5,4.6v0.1c0,2-2.1,2.1-7.3,2.7c-5.2,0.6-11.3,2.4-11.3,9.1c0,5.8,4.3,8.9,10,8.9 c4.6,0,7.4-2.2,8.7-4.7h0.2v4.1h6.7V28.3C86.5,20.5,80.1,18.1,74.4,18.1z M79.5,37.6c0,3.3-2.7,6.1-6.9,6.1c-2.9,0-5-1.3-5-3.9 s2.4-3.8,5.5-4.3c1.8-0.3,5.5-0.7,6.4-1.5V37.6z M131.2,11.1h-7v7.5h-4.3v5.5h4.3v16.5c0,5.6,4,8.3,9.3,8.2c1.2,0,2.4-0.2,3.6-0.5 v-5.5c-0.5,0.1-1.8,0.2-2.7,0.3c-1.8,0-3.2-0.6-3.2-3.4V24.1h5.8v-5.5h-5.8L131.2,11.1L131.2,11.1z'
            fill='#FFFFFF'
          ></path>
          <path
            d='M139.9,77.7c2.2,0,3.7-1.8,3.7-4.8c0-3-1.6-4.8-3.7-4.8c-1.6,0-2.5,0.9-2.9,1.6v-1.7v-3.4h-1.7v12.9h1.7V76 C137.4,76.8,138.3,77.7,139.9,77.7z M139.4,76.2c-1.5,0-2.5-1.1-2.5-3.4c0-2.2,1.1-3.3,2.5-3.3c1.4,0,2.5,0.8,2.5,3.3 C141.9,75.3,140.8,76.2,139.4,76.2z M151.6,68.3L150,73c-0.4,1.2-0.5,1.6-0.7,2.1c-0.2-0.4-0.3-0.8-0.9-2.3l-1.9-4.6h-1.9l4,9 l-0.5,1.3c-0.3,0.7-0.6,0.8-1.3,0.8H145V81h1.9c1.6,0,2.3-0.6,2.9-2.3l3.6-10.4L151.6,68.3L151.6,68.3z M165.7,77.7 c3.7,0,6.4-2.7,6.4-6.7c0-4-2.7-6.7-6.4-6.7s-6.5,2.7-6.5,6.7C159.2,75,162,77.7,165.7,77.7z M165.7,76c-2.8,0-4.5-2.2-4.5-5 c0-2.9,1.7-5,4.5-5c2.8,0,4.5,2.2,4.5,5C170.2,73.8,168.5,76,165.7,76z M181.3,77.4h2.2l-4.4-5.4l4.1-3.8H181l-4.7,4.6v-3v-5.3 h-1.7v12.9h1.7v-2.7l1.7-1.6L181.3,77.4z M188.3,77.4h2.2v-1.5h-1.9c-0.6,0-0.9-0.3-0.9-0.9v-5.3h2.5v-1.4h-2.5v-2.9H186v2.9h-2.1 v1.4h2.1v5.5C186,76.7,186.8,77.4,188.3,77.4z M194.9,77.7c1.5,0,2.3-0.6,3-1.6c0.1,0.8,0.4,1.4,1.7,1.4h1.3v-1.5h-0.9 c-0.4,0-0.5-0.1-0.5-0.5v-4.2c0-2-1.2-3.2-3.7-3.2c-2.4,0-3.7,1.3-4,2.6l1.7,0.5c0.2-0.7,0.8-1.6,2.4-1.6c1.3,0,1.9,0.6,1.9,1.7 v0.3c-3,0.2-5.8,1.1-5.8,3.6C191.9,76.7,193.1,77.7,194.9,77.7z M195.3,76.2c-1.1,0-1.6-0.5-1.6-1.3c0-1.5,1.8-2,4-2.2v0.9 C197.7,75.5,196.5,76.2,195.3,76.2z'
            fill='#FFFFFF'
          ></path>
        </g>
      </svg>
    </div>
  )
}
