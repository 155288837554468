import React, { useEffect } from 'react'
// import './ContactInfo.css'
import { Card } from 'react-bootstrap'
import lottie from 'lottie-web'
import christmasLottie from '../lottie/90315-christmas-tree.json'

// Purpose: Conditional render image on start page based on the actual month

// render YEAR via JS:
const date = new Date()
// const year = date.getFullYear()
const month = date.getMonth() + 1 // count from 0

const StartImage = () => {
  useEffect(() => {
    // Changed to be destroyed after one run:
    const instanceOfLottie =
    lottie.loadAnimation({
      container: document.querySelector('#christmas-lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: christmasLottie,
    })
    return () => instanceOfLottie.destroy()
  }, [])

  if (month >= 10) {
    return (
      <>
        <Card body style={{ backgroundColor: '#131313' }}>
          <div id='christmas-lottie' style={{ height: 400, backgroundColor: '#131313' }}></div>
        </Card>
      </>
    )
  } else {
    return null
  }
}

export default StartImage
