import React, { useEffect } from 'react'
import ContactInfo from '../components/ContactInfo'
import { Container, Row, Col, Card } from 'react-bootstrap'
// Auth0 buttons:
// import { LoginButton } from '../components/buttons/login-button';
// import { LogoutButton } from '../components/buttons/logout-button';
// import { NavBarButtons } from '../components/nav-buttons/nav-bar-buttons'
// import { ProfilePage } from './profile-page'
// ---------
import lottie from 'lottie-web'
import townLottie from '../lottie/56821-power-mining-deploy.json'

import './Home.css'

const Home = () => {
  // load lottie file onLoad:
  useEffect(() => {
    //  destroyed after one run:
    const instanceOfLottie = lottie.loadAnimation({
      container: document.querySelector('#town-lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: townLottie,
    })
    return () => instanceOfLottie.destroy()
  }, [])
  return (
    <>
      <Container>
        {/* <div className='mt-8 p-2'></div> */}
        <Row>
          <Col className='col-md-8 offset-md-2'>
            <Card bg='dark' style={{ marginTop: 30 }}>
              <Card.Body>
                {/* <LoginButton /> {' '}
                <LogoutButton /> {' '} */}
                {/* <NavBarButtons /> */}
                {/* <ProfilePage /> */}
                <p className='lead text-white' style={{opacity: '50%'}}>Welcome to:</p>
                <p className='display-4  mt-3 text-display'>
                  AirDash <br />
                </p>
                <p className='lead text-white'>- a web app, developed to obtain and handle data. </p>
                <p className='text-white'>This is only a start page. All functions are consumed via project URLs.</p>
                <br />
                <ContactInfo />
              </Card.Body>

              <div className='m-4' id='town-lottie' style={{ height: 200 }}></div>

              <div className='card-body'>
                <ul className='list-unstyled mt-4 mb-4'>
                  <li>
                    <p className='small text-white' style={{opacity: '50%'}}>
                      “Geek - a digital-technology expert or enthusiast, a person who has excessive enthusiasm for and some expertise about a specialized
                      subject or activity”
                    </p>
                  </li>
                </ul>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home
