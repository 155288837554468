import React from 'react'
import './ContactInfo.css'

// render YEAR via JS:
const date = new Date();
const year = date.getFullYear();

const ContactInfo = () => {
  return (
    <>
      <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
        <li className="li-text">
          Brought to you by the geeks at{' '}
          <a href='https://www.vslrbl.se' target='_blank' rel='noreferrer' className='link-text'>
            VSLRBL
          </a>{' '}
          © {year} {' '}
          <img src='https://www.vslrbl.se/favicon-32x32.png' alt='Logo' className='icon-img' />{' '}
        </li>
      </ul>
    </>
  )
}

export default ContactInfo
