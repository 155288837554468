import React from 'react'

import './Loader.css'

const Loader = () => {
  return (
    <>
      <p className='loading-text'>Requesting data for all deployed systems.</p>
      <p className='loading-text fade-in-text '>I'm doing my best, almost there..</p>
      <div className='lds-dual-ring'></div>
    </>
  )
}

export default Loader
