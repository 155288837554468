import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import ContactInfo from '../components/ContactInfo'
import { Container, Row, Col } from 'react-bootstrap'
import lottie from 'lottie-web'
import networkLottie from '../lottie/lf30_editor_mhdbnbjm.json'
import StartImage from '../components/Startimage'
// Nav bar for auth0:
import { NavBarButtons } from '../components/nav-buttons/nav-bar-buttons'

const BatStart = () => {
  // load lottie file onLoad:
  useEffect(() => {
    // Changed to be destroyed after one run:
    const instanceOfLottie = lottie.loadAnimation({
      container: document.querySelector('#net-lottie'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: networkLottie,
    })
    return () => instanceOfLottie.destroy()
  }, [])

  return (
    <Container>
      <Row>
        <Col>
          <ContactInfo />
        </Col>
      </Row>
      <Row style={{ marginBottom: '2rem', marginTop: '0.3rem' }}>
        <div id='net-lottie' style={{ height: '10rem' }}></div>
        <Col className='text-white'>
          <h3 className='text-white'>Welcome!</h3> <br />
          <p className='text-note-this' style={{ fontSize: '1.2rem' }}>
            ⚠️ Protected area, please log in. ⚠️
          </p>
          <p>
            Request access? <a href='http://www.vslrbl.se/' className='text-white'>Please get in contact.</a>
          </p>
          <br />
          <NavBarButtons />
        </Col>
      </Row>
      <Row></Row>
      {/* IMAGE ON START PAGE: */}
      <Row style={{ marginTop: 50 }}>
        <Col>
          <StartImage />
        </Col>
      </Row>
    </Container>
  )
}

export default BatStart
