import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { LoginButton } from './login-button'
import { LogoutButton } from './logout-button'
// import { SignupButton } from './signup-button'
import { AuthLogo } from '../auth0-logo'

import { UserInfo } from '../UserInfo'


export const NavBarButtons = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <div className='nav-bar__buttons' style={{marginBottom: '25px'}}>
      {!isAuthenticated && (
        <>
          <LoginButton /> 
          <AuthLogo />
        </>
      )}
      {isAuthenticated && (
        <>
          <UserInfo />
          <LogoutButton />
        </>
      )}
    </div>
  )
}
