import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Routes, Route, Link } from 'react-router-dom'

import { PageLoader } from './components/page-loader'
import { AuthenticationGuard } from './components/auth0-auth-guard'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { Container, Row, Col } from 'react-bootstrap'

import Home from './pages/Home'
import BatStart from './pages/BatStart'
import BatSystems from './pages/BatSystems'
import BatRouters from './pages/BatRouters'

// const reactVersion = React.version
// console.log('* Hey there friend. We are the robots. We come in peace. 🤖 *')

export const App = () => {
  const { isLoading } = useAuth0()
  if (isLoading) {
    return (
      <div className='page-layout'>
        <PageLoader />
      </div>
    )
  }
  return (
    <>
      <Routes>
        {/* <Container fluid> */}
        <Route path='/' index element={<Home />} />
        {/* <Route path='/profile' element={<ProfilePage />} /> */}
        <Route path='/bat' element={<BatStart />} />
        {/* LOCKED ROUTE: */}
        <Route path='/bat/systems' element={<AuthenticationGuard component={BatSystems} />} />
        {/* LOCKED ROUTE: */}
        <Route path='/bat/routers' element={<AuthenticationGuard component={BatRouters} />} />
        <Route path='*' element={<NoMatch />} />
        {/* </Container> */}
      </Routes>
    </>
  )
}
// Catch all URL errors:
function NoMatch() {
  return (
    <Container>
      <Row>
        <Col className='text-center'>
          <div className='text-display'>
            <h1>404</h1>
            <p>Page not found</p>
            <h2>Nothing to see here!</h2>
            <p>
              <Link to='/' className='text-link'>
                Go to the home page
              </Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
