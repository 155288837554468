import React from 'react'

export const PageLoader = () => {
  const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg'

  const styles = {
    // main: {
    //   backgroundColor: '#f1f1f1',
    //   width: '100%',
    // },
    // inputText: {
    //   padding: '10px',
    //   color: 'red',
    // },
    loader: {
      height: '5rem',
      width: '5rem',
      margin: 'auto',
      animation: 'spin 2s infinite linear',
    }
    
  }

  return (
    <div className='loader' style={styles.loader}>
      <img src={loadingImg} alt='Loading...' />
    </div>
  )
}
